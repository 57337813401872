






























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import CopyTextField from '@/components/CopyTextField.vue'
import CopiedSnackbar from '@/components/CopiedSnackbar.vue'

@Component({
  components: {
    CopyTextField,
    CopiedSnackbar
  }
})
export default class BusinessCard extends Vue {
  private text = 'presto.2ke@gmail.com'

  private display = false
  private result = ''
  /**
   * クリップボードにコピーして結果をスナックバーで表示します
   */
  private writeToClipboard() {
    this.$copyText(this.text)
      .then(() => this.showCopyResult('success'))
      .catch(() => this.showCopyResult('error'))
  }

  private showCopyResult(result: 'success' | 'error') {
    this.display = true
    this.result = result
  }
}
