



















































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import PreviewableImage from './PreviewableImage.vue'

@Component({
  components: {
    PreviewableImage
  }
})
export default class Base64ToImage extends Vue {
  private base64 = '';
}
