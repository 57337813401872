import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import IPAddress from '@/ip-address/IPAddress.vue'
import DateConverter from '@/date-converter/DateConverter.vue'
import ImageBase64Converter from '@/image-base64-converter/ImageBase64Converter.vue'
import UuidGenerator from '@/uuid-generator/UuidGenerator.vue'
import JsonFormatter from '@/json-formatter/JsonFormatter.vue'
import URLEncodeDecode from '@/url-encode-decode/URLEncodeDecode.vue'
import Home from '@/home/Home.vue'
import PrivacyPolicy from '@/privacy-policy/PrivacyPolicy.vue'
import About from '@/about/About.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'トップ',
      description: 'あるとちょっと便利なツールを載せています。'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: '運営者について',
      description: '運営者について自己紹介を記載しています。'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'プライバシーポリシーと免責事項',
      description: 'プライバシーポリシーと免責事項を掲載しています。'
    }
  },
  {
    path: '/uuid-generator',
    name: 'UuidGenerator',
    component: UuidGenerator,
    meta: {
      title: 'UUID生成ツール',
      description: '指定の個数分だけUUID文字列を生成します。大文字で生成することも可能です。',
      icon: 'mdi-form-textbox-password'
    }
  },
  {
    path: '/ip-address',
    name: 'IPAddress',
    component: IPAddress,
    meta: {
      title: 'IPアドレス確認ツール',
      description: 'あなたのグローバルIPアドレスを表示します。',
      icon: 'mdi-ip'
    }
  },
  {
    path: '/date-converter',
    name: 'DateConverter',
    component: DateConverter,
    meta: {
      title: '日付変換ツール',
      description: '指定の日付をいろいろな形式の文字列に変換します。moment.jsに則りフォーマットを変更することが可能です。',
      icon: 'mdi-clock-time-nine-outline'
    }
  },
  {
    path: '/image-base64-converter',
    name: 'ImageBase64Converter',
    component: ImageBase64Converter,
    meta: {
      title: '画像Base64変換ツール',
      description: '画像をアップロードするか、Base64文字列を入力すると変換します。',
      icon: 'mdi-image-multiple-outline'
    }
  },
  {
    path: '/url-encode-decode',
    name: 'URLEncodeDecode',
    component: URLEncodeDecode,
    meta: {
      title: 'URLエンコード・デコードツール',
      description: 'URLエンコードとURLデコードを行うツールです。',
      icon: 'mdi-link'
    }
  },
  {
    path: '/json-formatter',
    name: 'JsonFormatter',
    component: JsonFormatter,
    meta: {
      title: 'JSONフォーマットツール',
      description: 'JSON文字列のバリデーションやフォーマットをします。',
      icon: 'mdi-format-indent-increase'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 })
})

export default router
