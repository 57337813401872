





















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class Snackbar extends Vue {
  @PropSync('display')
  private syncedDisplay!: boolean;

  @Prop()
  private color!: 'success' | 'error';

  @Prop({ default: 2000 })
  private timeout!: number;

  @Prop()
  private message!: string;
}
