








































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import OneColumn from '@/layout/OneColumn.vue'

@Component({
  components: {
    OneColumn
  }
})
export default class PrivacyPolicy extends Vue {
}
