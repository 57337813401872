






























import { Component, PropSync, Vue } from 'vue-property-decorator'
import Home from '@/home/Home.vue'

@Component
export default class SideBar extends Vue {
  @PropSync('drawer')
  private syncedDrawer!: boolean;

  private get items() {
    return this.$router.options.routes
      ?.filter(route => !Home.EXCLUDE_LIST.includes(route.name as string))
      .filter(route => route.name) ?? []
  }

  private transPage(name: string) {
    if (this.$route.name === name) {
      return
    }
    this.$router.push({ name: name })
  }

  private get responsiveWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return 512
    }
    return 256
  }
}
