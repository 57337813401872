








































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Header extends Vue {
  private readonly footerLinks = [
    {
      name: 'About',
      label: '運営者について'
    },
    {
      name: 'PrivacyPolicy',
      label: 'プライバシーポリシーと免責事項'
    }
  ]
}
