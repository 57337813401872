


















































































import { Component, Vue } from 'vue-property-decorator'
import OneColumn from '@/layout/OneColumn.vue'
import CopiedSnackbar from '@/components/CopiedSnackbar.vue'
import { inputRequired, max, min } from '@/module/validate'

@Component({
  components: {
    OneColumn,
    CopiedSnackbar
  }
})
export default class JsonFormatter extends Vue {
  private input = ''
  private output = ''
  private setIndent = false
  private indentCount = JsonFormatter.DEFAULT_INDENT_COUNT
  private isError = false

  private copiedSnackbarParam = {
    display: false,
    result: 'success'
  }

  private static readonly DEFAULT_INDENT_COUNT = 4
  private static readonly MIN = 0
  private static readonly MAX = 10

  private get rules() {
    return [
      inputRequired(),
      min(JsonFormatter.MIN),
      max(JsonFormatter.MAX)
    ]
  }

  private inputJson() {
    if (!this.indentCount || this.indentCount < JsonFormatter.MIN || this.indentCount > JsonFormatter.MAX) {
      return
    }

    if (this.input === '') {
      this.output = ''
      this.isError = false
      return
    }

    try {
      const object = JSON.parse(this.input)
      const indent = this.setIndent ? +this.indentCount : JsonFormatter.DEFAULT_INDENT_COUNT
      this.output = JSON.stringify(object, null, indent)
      this.isError = false
    } catch (e) {
      this.output = e
      this.isError = true
    }
  }

  private writeToClipboard() {
    if (this.output === '') {
      return
    }

    this.copiedSnackbarParam.display = true
    this.$copyText(this.output)
      .then(() => {
        this.copiedSnackbarParam.result = 'success'
      })
      .catch(() => {
        this.copiedSnackbarParam.result = 'error'
      })
  }
}
