












































































































import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'
import filesize from 'filesize'
import ImageFileDrop from './ImageFileDrop.vue'
import PreviewableImage from './PreviewableImage.vue'
import CopyButton from '@/components/CopyButton.vue'
import { ImageFile } from '../ImageBase64Converter.vue'

@Component({
  components: {
    ImageFileDrop,
    PreviewableImage,
    CopyButton
  }
})
export default class ImageToBase64 extends Vue {
  private imageList: ImageFile[] = [];

  private async imageUploaded(fileList: FileList) {
    // 何も選択していない場合は処理しない
    if (fileList.length === 0) {
      return
    }

    this.loading(true)
    this.resetFileList()

    // 選択された画像ファイルからbase64文字列を取得しながら、上記で定義したinterface`ImageFile`オブジェクトへ格納
    const promises: Promise<string>[] = Array.from(fileList).map(file => this.readImageFileAsync(file))
    const base64List = await Promise.all(promises)
    base64List.forEach((base64, index) => {
      this.imageList.push({ file: fileList.item(index), base64 })
    })
    this.loading(false)
  }

  private getFilesize(size: number): string {
    return filesize(size)
  }

  private getLastModifiedDateString(date: Date): string {
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  private resetFileList() {
    this.imageList.splice(0)
  }

  private readImageFileAsync(file: File): Promise<string> {
    if (!file) {
      return new Promise((resolve) => {
        return resolve('')
      })
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  @Emit('copy')
  private clickTextarea(base64: string) {
    return base64
  }

  @Emit()
  private loading(isStart: boolean) {
    return isStart
  }
}
