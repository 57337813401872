


























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

/**
 * ロード中などに表示するプログレスコンポーネント
 */
@Component
export default class ProgressCircular extends Vue {
  @PropSync('display')
  private syncedDisplay!: boolean;

  @Prop({ default: '読み込み中' })
  private message!: string;
}
