






























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import moment from 'moment-timezone'

@Component
export default class ReleaseNotes extends Vue {
  private readonly releaseItems = [
    {
      color: 'primary',
      icon: 'mdi-form-textbox-password',
      date: '2021/03/09',
      message: 'UUID生成ツールを公開しました。'
    },
    {
      color: 'primary',
      icon: 'mdi-ip',
      date: '2021/03/09',
      message: 'IPアドレス確認ツールを公開しました。'
    },
    {
      color: 'primary',
      icon: 'mdi-clock-time-nine-outline',
      date: '2021/03/09',
      message: '日付変換ツールを公開しました。'
    },
    {
      color: 'primary',
      icon: 'mdi-image-multiple-outline',
      date: '2021/03/09',
      message: '画像Base64変換ツールを公開しました。'
    },
    {
      color: 'primary',
      icon: 'mdi-link',
      date: '2021/03/09',
      message: 'URLエンコード・デコードツールを公開しました。'
    },
    {
      color: 'primary',
      icon: 'mdi-format-indent-increase',
      date: '2021/03/09',
      message: 'JSONフォーマットツールを公開しました。'
    },
    {
      color: 'accent',
      icon: '',
      date: '2021/03/09',
      message: 'v.1.0 リリース'
    }
  ]

  private readonly defaultFormat = 'YYYY/MM/DD'
  private currentDateString = moment.tz(moment.tz.guess()).format(this.defaultFormat)
}
