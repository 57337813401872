






































import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * プレビュー可能な画像表示コンポーネント
 * 小さく表示された画像をクリックすると画面いっぱいに拡大して表示します。
 * 画面サイズの変更に伴ってプレビューしている画像サイズを伸縮します。
 */
@Component
export default class PreviewableImage extends Vue {
  @Prop()
  private src!: string;

  @Prop({ default: 300 })
  private height!: number | string;

  private previewWidth = window.innerWidth;
  private previewHeight = window.innerHeight;

  private overlay = false;

  public beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  private handleResize() {
    this.previewWidth = window.innerWidth
    this.previewHeight = window.innerHeight
  }

  private mounted() {
    window.addEventListener('resize', this.handleResize)
  }
}
