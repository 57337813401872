import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#92b2d4',
        secondary: '#fee100',
        accent: '#fb857f',
        error: '#de3b77',
        info: '#0078b9',
        success: '#00ab75',
        warning: '#ffab36'
      },
      dark: {
        primary: '#92b2d4',
        secondary: '#fee100',
        accent: '#fb857f',
        error: '#de3b77',
        info: '#0078b9',
        success: '#00ab75',
        warning: '#ffab36'
      }
    }
  }
})
