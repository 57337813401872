











import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import Header from '@/layout/Header.vue'
import Footer from '@/layout/Footer.vue'
import SideBar from '@/layout/SideBar.vue'
import { Route } from 'vue-router'

@Component({
  components: {
    Header,
    Footer,
    SideBar
  }
})
export default class App extends Vue {
  private drawer = false;

  // titleを設定
  private static setTitle(title: string | undefined) {
    document.title = title
      ? title + ' | presto-web-tools'
      : 'presto-web-tools'
  }

  // メタ情報のdescriptionを設定
  private static setDescription(description: string | undefined) {
    const element = document.querySelector("meta[name='description']")
    if (element) {
      const descriptionText = description
        ? description + ' | presto-web-tools'
        : 'description is not set'
      element.setAttribute('content', descriptionText)
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  private watchTitleDesc(routeInstance: Route) {
    App.setTitle(routeInstance.meta.title)
    App.setDescription(routeInstance.meta.description)
  }

  private mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}
