export const inputRequired = () => {
  return (v: string) => !!v || '入力してください'
}

export const min = (value: number) => {
  return (v: number) => (v >= value) || `${value.toLocaleString()}以上の数字を入力してください。`
}

export const max = (value: number) => {
  return (v: number) => (v <= value) || `${value.toLocaleString()}以下の数字を入力してください。`
}
