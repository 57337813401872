





























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

/**
 * 画像ファイルをアップロードするコンポーネント
 */
@Component
export default class ImageFileDrop extends Vue {
  @Prop({ type: Boolean, default: false })
  private multiple!: boolean;

  private dragover = false;
  private changeEvent(e: Event) {
    const target = (e.target as HTMLInputElement)
    if (target.files) {
      this.filesSelected(target.files)
    }
  }

  private dropEvent(dragevent: DragEvent) {
    dragevent.preventDefault()
    if (dragevent.dataTransfer) {
      this.filesSelected(dragevent.dataTransfer.files)
    }
  }

  /**
   * upload event...
   */
  @Emit('selected')
  private filesSelected(fileList: FileList) {
    this.dragover = false
    return fileList
  }
}
