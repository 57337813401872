






































































































































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment-timezone'
import 'moment/locale/ja'
// すべてのロケールを使う場合は以下を使う
// import 'moment/min/locales'
import Snackbar from '@/components/Snackbar.vue'
import CopiedSnackbar from '@/components/CopiedSnackbar.vue'
import OneColumn from '@/layout/OneColumn.vue'

@Component({
  components: {
    OneColumn,
    Snackbar,
    CopiedSnackbar
  }
})
export default class DateConverter extends Vue {
  private copiedSnackbarParam = {
    display: false,
    result: 'success'
  };

  private snackbarParam = {
    display: false,
    color: 'success',
    message: ''
  };

  // 現在時刻、時刻指定でも使うタイムゾーンとロケール、表示するフォーマット
  private readonly timezones = moment.tz.names();
  private readonly locales = moment.locales();
  private readonly formatStyles = [
    {
      title: 'ISO 8601 形式',
      format: '',
      formatHint: '(フォーマットを指定しない場合の形式)'
    },
    {
      title: 'スラッシュ区切り',
      format: 'YYYY/MM/DD HH:mm:ss',
      formatHint: 'YYYY/MM/DD HH:mm:ss'
    },
    {
      title: 'スラッシュ区切り (日付のみ)',
      format: 'YYYY/MM/DD',
      formatHint: 'YYYY/MM/DD'
    },
    {
      title: '区切りなし',
      format: 'YYYYMMDD HHmmss',
      formatHint: 'YYYYMMDD HHmmss'
    },
    {
      title: '区切りなし (日付のみ)',
      format: 'YYYYMMDD',
      formatHint: 'YYYYMMDD'
    },
    {
      title: 'UnixTime (ミリ秒)',
      format: 'x',
      formatHint: 'x'
    },
    {
      title: 'ロケールに合わせた形式',
      format: 'LLLL',
      formatHint: 'LLLL (ロケールに合わせた形式)',
      localeSelectable: true
    },
    {
      title: 'フォーマット指定',
      format: 'YYYY/MM/DD HH:mm:ss',
      formatHint: 'YYYY/MM/DD HH:mm:ss',
      formattable: true
    }
  ];

  private get formattedDates(): string[] {
    // ロケールを合わせて時刻を取得する
    moment.locale(this.selectedSpecifyDateLocale)
    const specifyDate = moment.tz(this.specifyDateMainString, this.defaultFormat, true, this.selectedSpecifyDateTimezone)

    if (!specifyDate.isValid()) {
      return this.formatStyles.map(() => '')
    }
    return this.formatStyles.map((formatStyle) => specifyDate.format(formatStyle.format))
  };

  private readonly defaultFormat = 'YYYY-MM-DD HH:mm:ss';

  // 時刻指定のメイン表示、タイムゾーンとロケール
  private specifyDateMainString = '';
  private selectedSpecifyDateTimezone = 'Asia/Tokyo';
  private selectedSpecifyDateLocale = 'ja';
  private timeSpecificationButtonDisabled = false;

  private readonly specifyDateManipulateButtons = [
    [
      { cols: 4, md: 4, text: '1時間前', click: this.onManipulateButtonClick, amount: -1, key: 'hours' },
      { cols: 4, md: 4, text: '1分前', click: this.onManipulateButtonClick, amount: -1, key: 'minutes' },
      { cols: 4, md: 4, text: '1秒前', click: this.onManipulateButtonClick, amount: -1, key: 'seconds' },
      { cols: 12, md: 12, text: '現在時刻', click: this.onCurrentDateTimeButtonClick, excludeDisabled: true },
      { cols: 4, md: 4, text: '1秒後', click: this.onManipulateButtonClick, amount: 1, key: 'seconds' },
      { cols: 4, md: 4, text: '1分後', click: this.onManipulateButtonClick, amount: 1, key: 'minutes' },
      { cols: 4, md: 4, text: '1時間後', click: this.onManipulateButtonClick, amount: 1, key: 'hours' }
    ],
    [
      { cols: 6, md: 3, text: '1年前', click: this.onManipulateButtonClick, amount: -1, key: 'years' },
      { cols: 6, md: 3, text: '1ヶ月前', click: this.onManipulateButtonClick, amount: -1, key: 'months' },
      { cols: 6, md: 3, text: '7日前', click: this.onManipulateButtonClick, amount: -7, key: 'days' },
      { cols: 6, md: 3, text: '1日前', click: this.onManipulateButtonClick, amount: -1, key: 'days' },
      { cols: 12, md: 12, text: '今日', click: this.onCurrentDateButtonClick, excludeDisabled: true },
      { cols: 6, md: 3, text: '1日後', click: this.onManipulateButtonClick, amount: 1, key: 'days' },
      { cols: 6, md: 3, text: '7日後', click: this.onManipulateButtonClick, amount: 7, key: 'days' },
      { cols: 6, md: 3, text: '1ヶ月後', click: this.onManipulateButtonClick, amount: 1, key: 'months' },
      { cols: 6, md: 3, text: '1年後', click: this.onManipulateButtonClick, amount: 1, key: 'years' }
    ]
  ];

  private showCopyResult(result: 'success' | 'error') {
    this.copiedSnackbarParam.display = true
    this.copiedSnackbarParam.result = result
  }

  private showResult(color: 'success' | 'error', message: string) {
    this.snackbarParam.display = true
    this.snackbarParam.color = color
    this.snackbarParam.message = message
  }

  /**
   * クリップボードにコピーして結果をスナックバーで表示します
   */
  private writeToClipboard(text: string) {
    this.$copyText(text)
      .then(() => {
        this.showCopyResult('success')
      })
      .catch(() => {
        this.showCopyResult('error')
      })
  }

  private resetSpecifyDateTimezone() {
    this.selectedSpecifyDateTimezone = moment.tz.guess()
  }

  private resetSpecifyDateLocale() {
    this.selectedSpecifyDateLocale = moment.tz().locale()
  }

  /**
   * 現在時刻ボタン押下イベント
   */
  private onCurrentDateTimeButtonClick() {
    this.specifyDateMainString = moment.tz(this.selectedSpecifyDateTimezone)
      .format(this.defaultFormat)
    this.refreshSpecifyDate()
  }

  /**
   * 今日ボタン押下イベント
   */
  private onCurrentDateButtonClick() {
    this.specifyDateMainString = moment.tz(this.selectedSpecifyDateTimezone)
      .seconds(0)
      .minutes(0)
      .hours(0)
      .format(this.defaultFormat)
    this.refreshSpecifyDate()
  }

  /**
   * 時刻操作ボタン押下イベント
   */
  private onManipulateButtonClick(amount: number, key: moment.unitOfTime.DurationConstructor) {
    this.specifyDateMainString = moment.tz(this.specifyDateMainString, this.selectedSpecifyDateTimezone)
      .add(amount, key)
      .format(this.defaultFormat)
    this.refreshSpecifyDate()
  }

  /**
   * 【時刻指定】メイン表示に合わせて他の形式の時刻を更新します。
   * メイン表示がフォーマットに則っている場合にのみ処理を行います。
   */
  private refreshSpecifyDate() {
    const newMoment = moment.tz(this.specifyDateMainString, this.defaultFormat, true, this.selectedSpecifyDateTimezone)

    // 文字列がフォーマットに則っていない場合はエラースナックバーを表示して時刻指定をdisabledにする
    if (!newMoment.isValid()) {
      this.showResult('error', '時刻のフォーマットが正しくありません。')
      this.timeSpecificationButtonDisabled = true
      return
    }

    // エラーがない場合はdisabledを解除する
    this.timeSpecificationButtonDisabled = false

    const specifyDate = moment.tz(this.specifyDateMainString, this.selectedSpecifyDateTimezone)
    this.specifyDateMainString = specifyDate.format(this.defaultFormat)
  }

  /**
   * タイムゾーンを変更した時のイベント
   * タイムゾーンに合わせた現在時刻を取得しメイン表示の文字列を設定する
   */
  private changeTimezone() {
    const specifyDate = moment.tz(this.selectedSpecifyDateTimezone)
    this.specifyDateMainString = specifyDate.format(this.defaultFormat)
  }

  private mounted() {
    // 時刻指定の初期表示を設定
    this.resetSpecifyDateTimezone()
    this.resetSpecifyDateLocale()

    // 時刻指定の時刻を初期表示する
    this.onCurrentDateTimeButtonClick()
  }
}
