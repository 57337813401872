




import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import Snackbar from '@/components/Snackbar.vue'

@Component({
  components: {
    Snackbar
  }
})
export default class CopiedSnackbar extends Vue {
  @Prop()
  private result!: 'success' | 'error';

  @Prop({ default: 2000 })
  private timeout!: number;

  @PropSync('display')
  private syncedDisplay!: boolean;

  private get message() {
    return this.result === 'success' ? 'クリップボードへコピーしました。' : 'コピーに失敗しました。'
  }
}
