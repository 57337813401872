















































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import OneColumn from '@/layout/OneColumn.vue'
import CopyButton from '@/components/CopyButton.vue'
import CopyTextField from '@/components/CopyTextField.vue'

@Component({
  components: {
    OneColumn,
    CopyButton,
    CopyTextField
  }
})
export default class URLEncodeDecode extends Vue {
  private input = ''
  private readonly conversionList = [
    { name: 'encodeURI', conversion: (input: string) => encodeURI(input) },
    { name: 'encodeURIComponent', conversion: (input: string) => encodeURIComponent(input) },
    { name: 'decodeURI', conversion: (input: string) => decodeURI(input) },
    { name: 'decodeURIComponent', conversion: (input: string) => decodeURIComponent(input) }
  ]

  private conversion = this.conversionList[0]

  private get output(): string {
    return this.conversion.conversion(this.input)
  }
}
