




































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import OneColumn from '@/layout/OneColumn.vue'
import CopiedSnackbar from '@/components/CopiedSnackbar.vue'
import CopyButton from '@/components/CopyButton.vue'

@Component({
  components: {
    OneColumn,
    CopiedSnackbar,
    CopyButton
  }
})
export default class IPAddress extends Vue {
  private ipAddress = '';

  private copiedSnackbarParam = {
    display: false,
    result: 'success'
  }

  private async mounted() {
    try {
      this.ipAddress = await IPAddress.getAddress()
    } catch (e) {
      this.ipAddress = '広告ブロックを無効にしてください。'
    }
  }

  private static getAddress() {
    return fetch('https://ipv4.jsonip.com')
      .then(response => response.json())
      .then(data => data.ip)
  }

  private writeToClipboard(text: string) {
    this.copiedSnackbarParam.display = true
    this.$copyText(text)
      .then(() => {
        this.copiedSnackbarParam.result = 'success'
      })
      .catch(() => {
        this.copiedSnackbarParam.result = 'error'
      })
  }
}
