



















































































import { Component, Vue } from 'vue-property-decorator'
import CopiedSnackbar from '../components/CopiedSnackbar.vue'
import { v4 as uuidv4 } from 'uuid'
import { inputRequired, max, min } from '@/module/validate'
import { range } from '@/util/array-util'
import OneColumn from '@/layout/OneColumn.vue'

@Component({
  components: {
    OneColumn,
    CopiedSnackbar
  }
})
export default class UuidGenerator extends Vue {
  private generateCount = 1
  private isValid = true
  private isUpperCase = false
  private uuids: string[] = []

  private copiedSnackbarParam = {
    display: false,
    result: 'success'
  }

  private static readonly MIN = 1
  private static readonly MAX = 100

  private get rules() {
    return [
      inputRequired(),
      min(UuidGenerator.MIN),
      max(UuidGenerator.MAX)
    ]
  }

  private generateUuids() {
    if (this.generateCount < UuidGenerator.MIN || this.generateCount > UuidGenerator.MAX) {
      return
    }

    this.uuids = range(0, this.generateCount)
      .map(() => this.isUpperCase ? uuidv4().toUpperCase() : uuidv4())
  }

  private writeToClipboard(text: string) {
    this.copiedSnackbarParam.display = true
    this.$copyText(text)
      .then(() => {
        this.copiedSnackbarParam.result = 'success'
      })
      .catch(() => {
        this.copiedSnackbarParam.result = 'error'
      })
  }
}
