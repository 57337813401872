



































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import OneColumn from '@/layout/OneColumn.vue'
import CopiedSnackbar from '@/components/CopiedSnackbar.vue'
import ProgressCircular from '@/components/ProgressCircular.vue'
import ImageToBase64 from './components/ImageToBase64.vue'
import Base64ToImage from './components/Base64ToImage.vue'

export interface ImageFile {
  file: File | null;
  base64: string;
}

@Component({
  components: {
    OneColumn,
    CopiedSnackbar,
    ProgressCircular,
    ImageToBase64,
    Base64ToImage
  }
})
export default class ImageBase64Converter extends Vue {
  /**
   * コピースナックバーの表示状態と色
   */
  private copiedSnackbarParam = {
    display: false,
    result: 'success'
  };

  /**
   * ロード中のオーバーレイを表示するフラグ
   */
  private nowLoading = false;

  private tabTitles = [
    '画像 → Base64',
    'Base64 → 画像'
  ];

  private tab = 0;

  private showCopyResult(result: 'success' | 'error') {
    this.copiedSnackbarParam.display = true
    this.copiedSnackbarParam.result = result
  }

  private displayProgress(isShow: boolean) {
    this.nowLoading = isShow
  }

  /**
   * クリップボードにコピーして結果をスナックバーで表示します
   */
  private writeToClipboard(text: string) {
    this.$copyText(text)
      .then(() => {
        this.showCopyResult('success')
      })
      .catch(() => {
        this.showCopyResult('error')
      })
  }
}
