














import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import OneColumn from '@/layout/OneColumn.vue'
import CopyButton from '@/components/CopyButton.vue'

@Component({
  components: {
    OneColumn,
    CopyButton
  }
})
export default class Home extends Vue {
  static readonly EXCLUDE_LIST = [
    'Home',
    'About',
    'PrivacyPolicy'
  ]

  private get routeList() {
    return this.$router.options.routes
      ?.filter(route => !Home.EXCLUDE_LIST.includes(route.name as string))
      .filter(route => route.name) ?? []
  }
}
