













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import CopiedSnackbar from '@/components/CopiedSnackbar.vue'

@Component({
  components: {
    CopiedSnackbar
  }
})
export default class CopyTextField extends Vue {
  @Prop()
  private value!: string

  @Prop()
  private label!: string

  private display = false
  private result = ''

  /**
   * クリップボードにコピーして結果をスナックバーで表示します
   */
  private writeToClipboard() {
    this.$copyText(this.value)
      .then(() => this.showCopyResult('success'))
      .catch(() => this.showCopyResult('error'))
  }

  private showCopyResult(result: 'success' | 'error') {
    this.display = true
    this.result = result
  }
}
