






























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import OneColumn from '@/layout/OneColumn.vue'
import BusinessCard from '@/components/BusinessCard.vue'
import ReleaseNotes from '@/components/ReleaseNotes.vue'

@Component({
  components: {
    OneColumn,
    BusinessCard,
    ReleaseNotes
  }
})
export default class AboutUs extends Vue {
}
