




























import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class Header extends Vue {
  @PropSync('drawer')
  private syncedDrawer!: boolean;
}
